import React, { useRef } from 'react';
import JobComponent from '../component/Career/jobComponent';
import JobHomeComponent from '../component/Career/jobHomeComponent';
import FooterComponent from '../component/footerComponent';
import { graphql } from 'gatsby';
import Layout from '../component/layout';
const SocialMediaMarketing = props => {
  const {
    data: {
      wpPost: {
        seo,
        jobHomeSection: {
          jobHomeSectionDescription,
          jobHomeSectionHybrid,
          jobHomeSectionLocation,
          jobHomeSectionTitle,
        },
        jobArticleSection: { jobArticleSectionText },
      },
    },
    pageContext: { title },
  } = props;
  const learnMoreRef = useRef(null);
  const onLearnMoreClick = () => {
    console.log(learnMoreRef.current.scrollTo());
    if (learnMoreRef && learnMoreRef.current) {
      learnMoreRef.current.scrollIntoView();
    }
  };
  return (
    <Layout title={title} seo={seo}>
      <JobHomeComponent
        jobPlace={jobHomeSectionLocation}
        job={jobHomeSectionHybrid}
        jobTitle={jobHomeSectionTitle}
        jobDescription={jobHomeSectionDescription}
        applyButton='Apply now'
        learnButton='Learn more'
        onLearnMoreClick={onLearnMoreClick}
      />
      <JobComponent
        // jobCompanyTitle="Company"
        jobCompanyDescription={jobArticleSectionText}
        jobContact='Contact'
        jobContactName='Imelda Seferi'
        jobContactPosition='People & Recruitment Manager'
        jobContactEmail='imelda@uptechshpk.com'
        jobContactNumber='+355 67 544 6541'
        jobContactButton='Apply for this job'
        learnMoreRef={learnMoreRef}
      />
      <FooterComponent />
    </Layout>
  );
};
export const query = graphql`
  query {
    wpPost(slug: { eq: "social-media-marketing-specialist" }) {
      seo {
        metaDesc
        title
      }
      jobHomeSection {
        jobHomeSectionDescription
        jobHomeSectionHybrid
        jobHomeSectionLocation
        jobHomeSectionTitle
        jobHomeSectionBackgroundImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
      jobArticleSection {
        jobArticleSectionText
      }
    }
  }
`;
export default SocialMediaMarketing;
